<template>
    <Header :title="title"/>
    <Navigation :admin="true"/>
    <router-view/>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import Header from '@/components/Header.vue';

export default {
    name: 'Home',
    components: {
        Navigation,
        Header,
    },
    data() {
        return {
            title: null,
            name: null,
        };
    },
    watch: {
        $route(to) {
            this.title = to.meta.title;
        },
    },
};
</script>

<style scoped lang="less">

</style>
